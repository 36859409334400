// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    ledgerList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedLedger: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'ledger',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setLedgers(state, action) {
            state.ledgerList = action.payload;
        },
        setLedgerRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setLedgerPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedLedger(state, action) {
            state.selectedLedger = action.payload;
        },
        setLedger(state, action) {
            const data = action.payload;
            const rowIndex = state.ledgerList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.ledgerList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedLedger, setLedgerRowPerPage, setLedgerPage } = slice.actions;
// ----------------------------------------------------------------------

export function getLedgers(data) {
    return async () => {
        try {
            const response = await axios.post('/ledger/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setLedgers(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getLedger(id) {
    return async () => {
        try {
            const response = await axios.post('/ledger/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setLedger(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveLedger(data) {
    return async function () {
        const response = await axios.post('ledger/save', data);
        return response;
    };
}

export function updateLedger(data) {
    return async function () {
        const response = await axios.post('ledger/update', data);
        return response;
    };
}

export function deleteLedger(data) {
    return async function () {
        const response = await axios.post('ledger/delete', data);
        return response;
    };
}
