// action - state management
import { LOGIN, LOGOUT, REGISTER, BRANCH_CHANGE, UPDATE } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    company: null,
    functionalSetting: null,
    branch: null,
    error: false,
    errorMessage: ''
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user, company, functionalSetting, branch } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                company,
                functionalSetting,
                branch
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                company: null,
                functionalSetting: null,
                branch: null
            };
        }
        case BRANCH_CHANGE: {
            const branch = action.payload;
            return {
                ...state,
                branch
            };
        }
        case UPDATE: {
            const user = action.payload;
            return {
                ...state,
                user
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
