// third-party
import { FormattedMessage } from 'react-intl';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconFile,
    IconDeviceMobile
} from '@tabler/icons';
import useAuth from 'hooks/useAuth';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    PeopleAltIcon,
    SettingsApplicationsIcon,
    IconFile,
    IconDeviceMobile
};
//
// ==============================|| APPLICATION MENU ITEMS ||============================== //

function Inventory() {
    const { user } = useAuth();
    let permissions = [];
    if (user) {
        permissions = user.user_role.permissions.map((permission) => permission.name);
    }

    let masterChildren = [
        {
            permission: 'master.company.view',
            id: 'company',
            title: <FormattedMessage id="company" />,
            type: 'item',
            url: '/masters/company',
            breadcrumbs: true
        },
        {
            permission: 'master.branch.list',
            id: 'branch',
            title: <FormattedMessage id="branch" />,
            type: 'item',
            url: '/masters/branch',
            breadcrumbs: true
        },
        {
            permission: 'master.tax.list',
            id: 'tax',
            title: <FormattedMessage id="tax" />,
            type: 'item',
            url: '/masters/tax',
            breadcrumbs: true
        },
        {
            permission: 'master.category.list',
            id: 'category',
            title: <FormattedMessage id="category" />,
            type: 'item',
            url: '/masters/category',
            breadcrumbs: true
        },
        {
            permission: 'master.price_group.list',
            id: 'price_group',
            title: <FormattedMessage id="price_group" />,
            type: 'item',
            url: '/masters/price-group',
            breadcrumbs: true
        },
        {
            permission: 'master.customer.list',
            id: 'customer',
            title: <FormattedMessage id="customer" />,
            type: 'item',
            url: '/masters/customer',
            breadcrumbs: true
        },

        {
            permission: 'master.supplier.list',
            id: 'supplier',
            title: <FormattedMessage id="supplier" />,
            type: 'item',
            url: '/masters/supplier',
            breadcrumbs: true
        },

        {
            permission: 'master.supplier.list',
            id: 'service',
            title: <FormattedMessage id="service" />,
            type: 'item',
            url: '/masters/service',
            breadcrumbs: true
        },

        {
            permission: 'master.service-category.list',
            id: 'service_category',
            title: <FormattedMessage id="service_category" />,
            type: 'item',
            url: '/masters/service-category',
            breadcrumbs: true
        },

        {
            permission: 'master.item.list',
            id: 'item',
            title: <FormattedMessage id="item" />,
            type: 'item',
            url: '/masters/item',
            breadcrumbs: true
        },
        {
            permission: 'master.price_list.list',
            id: 'price_list',
            title: <FormattedMessage id="price_list" />,
            type: 'item',
            url: '/masters/price-list',
            breadcrumbs: true
        }
    ];
    masterChildren = masterChildren.filter((item) => permissions.includes(item.permission));
    let mobileChildren = [
        {
            permission: 'mobile.price_list.save',
            id: 'price_list_mobile',
            title: <FormattedMessage id="price_list" />,
            type: 'item',
            url: 'mobile/price-list',
            breadcrumbs: true
        },
        {
            permission: 'master.crease.list',
            id: 'crease',
            title: <FormattedMessage id="crease" />,
            type: 'item',
            url: '/masters/crease',
            breadcrumbs: true
        },
        {
            permission: 'master.offer-banner.list',
            id: 'offer_banner',
            title: <FormattedMessage id="offer_banner" />,
            type: 'item',
            url: '/masters/offer-banner',
            breadcrumbs: true
        },
        {
            permission: 'master.bag.list',
            id: 'bag',
            title: <FormattedMessage id="bag" />,
            type: 'item',
            url: '/masters/bag',
            breadcrumbs: true
        },
        {
            permission: 'mobile.coupon.save',
            id: 'coupon',
            title: <FormattedMessage id="coupon" />,
            type: 'item',
            url: 'mobile/coupon',
            breadcrumbs: true
        }
    ];
    mobileChildren = mobileChildren.filter((item) => permissions.includes(item.permission));
    let transactionChilden = [
        {
            permission: 'transaction.sale.save',
            id: 'pos',
            title: <FormattedMessage id="pos" />,
            type: 'item',
            url: '/pos',
            breadcrumbs: false
        },
        {
            permission: 'transaction.purchase.list',
            id: 'purchase',
            title: <FormattedMessage id="purchase" />,
            type: 'item',
            url: '/purchase',
            breadcrumbs: false
        },
        {
            permission: 'transaction.quotation.list',
            id: 'sale_quotation',
            title: <FormattedMessage id="sale_quotation" />,
            type: 'item',
            url: '/sale-quotation',
            breadcrumbs: false
        },
        {
            permission: 'transaction.supplier_payment.list',
            id: 'supplier_payment',
            title: <FormattedMessage id="supplier_payment" />,
            type: 'item',
            url: '/supplier-payment',
            breadcrumbs: false
        }
    ];
    transactionChilden = transactionChilden.filter((item) => permissions.includes(item.permission));
    let reportChildren = [
        {
            permission: 'master.item.list',
            id: 'stock_list',
            title: <FormattedMessage id="stock_list" />,
            type: 'item',
            url: '/reports/stock-list',
            breadcrumbs: true
        },
        {
            permission: 'reports.pickup.list',
            id: 'pickup-report',
            title: <FormattedMessage id="pickup-report" />,
            type: 'item',
            url: '/reports/pickup-report',
            breadcrumbs: true
        },
        {
            permission: 'reports.schedule.list',
            id: 'schedule-report',
            title: <FormattedMessage id="schedule-report" />,
            type: 'item',
            url: '/reports/schedule-report',
            breadcrumbs: true
        },
        {
            permission: 'reports.order.list',
            id: 'order-list',
            title: <FormattedMessage id="order-list" />,
            type: 'item',
            url: '/reports/sale-order',
            breadcrumbs: true
        },
        {
            permission: 'reports.sales.daily-sales',
            id: 'dailysales',
            title: <FormattedMessage id="dailysales" />,
            type: 'item',
            url: '/reports/daily-sales',
            breadcrumbs: true
        },
        {
            permission: 'reports.daily_collection.list',
            id: 'daily_collection',
            title: <FormattedMessage id="daily_collection" />,
            type: 'item',
            url: '/reports/daily-collection',
            breadcrumbs: true
        },
        {
            permission: 'reports.customer_outstanding.list',
            id: 'customer_outstanding',
            title: <FormattedMessage id="customer_outstanding" />,
            type: 'item',
            url: '/reports/customer-outstanding',
            breadcrumbs: true
        }
    ];
    reportChildren = reportChildren.filter((item) => permissions.includes(item.permission));
    let hrChildran = [
        {
            permission: 'hr.department.list',
            id: 'department',
            title: <FormattedMessage id="department" />,
            type: 'item',
            url: '/masters/department',
            breadcrumbs: true
        },
        {
            permission: 'hr.designation.list',
            id: 'designation',
            title: <FormattedMessage id="designation" />,
            type: 'item',
            url: '/masters/designation',
            breadcrumbs: true
        },
        {
            permission: 'hr.user_role.list',
            id: 'user-role',
            title: <FormattedMessage id="user-role" />,
            type: 'item',
            url: '/masters/user-role',
            breadcrumbs: true
        },
        {
            permission: 'hr.user.list',
            id: 'user',
            title: <FormattedMessage id="user" />,
            type: 'item',
            url: '/masters/user',
            breadcrumbs: true
        }
    ];

    hrChildran = hrChildran.filter((item) => permissions.includes(item.permission));

    let settingChildren = [
        {
            permission: 'setting.modeofpay.list',
            id: 'modeofpay',
            title: <FormattedMessage id="modeofpay" />,
            type: 'item',
            url: '/masters/modeofpay',
            breadcrumbs: true
        }
    ];

    settingChildren = settingChildren.filter((item) => permissions.includes(item.permission));

    return {
        id: 'masters',
        title: <FormattedMessage id="inventory" />,
        type: 'group',
        children: [
            {
                id: 'master',
                title: <FormattedMessage id="master" />,
                type: 'collapse',
                icon: icons.IconBasket,
                children: masterChildren
            },
            {
                id: 'mobile',
                title: <FormattedMessage id="mobile" />,
                type: 'collapse',
                icon: icons.IconDeviceMobile,
                children: mobileChildren
            },
            {
                id: 'transaction',
                title: <FormattedMessage id="transaction" />,
                type: 'collapse',
                icon: icons.IconCalendar,
                children: transactionChilden
            },
            {
                id: 'reports',
                title: <FormattedMessage id="reports" />,
                type: 'collapse',
                icon: icons.IconFile,
                children: reportChildren
            },
            {
                id: 'hr',
                title: <FormattedMessage id="hr" />,
                type: 'collapse',
                icon: icons.PeopleAltIcon,
                children: hrChildran
            },
            {
                id: 'settings',
                title: <FormattedMessage id="settings" />,
                type: 'collapse',
                icon: icons.SettingsApplicationsIcon,
                children: settingChildren
            }
        ]
    };
}

export default Inventory;
