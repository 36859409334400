// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    branchList: [],
    selectedBranch: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setBranches(state, action) {
            state.branchList = action.payload;
        },
        setSelectedBranch(state, action) {
            state.selectedBranch = action.payload;
        },
        setBranch(state, action) {
            const data = action.payload;
            const rowIndex = state.branchList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.branchList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedBranch } = slice.actions;
// ----------------------------------------------------------------------

export function getBranches() {
    return async () => {
        try {
            const response = await axios.post('/branch/list');
            if (response.data.status) {
                dispatch(slice.actions.setBranches(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getBranch(id) {
    return async () => {
        try {
            const response = await axios.post('/branch/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setBranch(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveBranch(data) {
    return async function () {
        const response = await axios.post('branch/save', data);
        return response;
    };
}

export function updateBranch(data) {
    return async function () {
        const response = await axios.post('branch/update', data);
        return response;
    };
}

export function deleteBranch(data) {
    return async function () {
        const response = await axios.post('branch/delete', data);
        return response;
    };
}
