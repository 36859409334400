// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    scheduleList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedSchedule: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSchedules(state, action) {
            state.scheduleList = action.payload;
        },
        setScheduleRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setSchedulePage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedSchedule(state, action) {
            state.selectedSchedule = action.payload;
        },
        setSchedule(state, action) {
            const data = action.payload;
            const rowIndex = state.scheduleList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.scheduleList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedSchedule, setScheduleRowPerPage, setSchedulePage, setSchedules } = slice.actions;
// ----------------------------------------------------------------------

export function getSchedules(data) {
    return async () => {
        try {
            const response = await axios.post('/schedule/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setSchedules(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getSchedule(id) {
    return async () => {
        try {
            const response = await axios.post('/schedule/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSelectedSchedule(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function markAsDone(data) {
    return async function () {
        const response = await axios.post('/schedule/mark-as-done', data);
        return response;
    };
}

export function deliveryCompleted(data) {
    return async function () {
        const response = await axios.post('/schedule/delivery-completed', data);
        return response;
    };
}

export function deleteSchedule(data) {
    return async function () {
        const response = await axios.post('/schedule/delete', data);
        return response;
    };
}
