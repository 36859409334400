// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    offerBannerList: [],
    selectedOfferBanner: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'offerBanner',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setOfferBanners(state, action) {
            state.offerBannerList = action.payload;
        },
        setSelectedOfferBanner(state, action) {
            state.selectedOfferBanner = action.payload;
        },
        setOfferBanner(state, action) {
            const data = action.payload;
            const rowIndex = state.offerBannerList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.offerBannerList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedOfferBanner } = slice.actions;
// ----------------------------------------------------------------------

export function getOfferBanners() {
    return async () => {
        try {
            const response = await axios.post('/offer-banner/list');
            if (response.data.status) {
                dispatch(slice.actions.setOfferBanners(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getOfferBanner(id) {
    return async () => {
        try {
            const response = await axios.post('/offer-banner/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setOfferBanner(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveOfferBanner(data) {
    return async function () {
        const response = await axios.post('offer-banner/save', data);
        return response;
    };
}

export function updateOfferBanner(data) {
    return async function () {
        const response = await axios.post('offer-banner/update', data);
        return response;
    };
}

export function deleteOfferBanner(data) {
    return async function () {
        const response = await axios.post('offer-banner/delete', data);
        return response;
    };
}
