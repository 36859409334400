// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    supplierPaymentList: [],
    billList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedSupplierPayment: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'supplierPayment',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSupplierPayments(state, action) {
            state.supplierPaymentList = action.payload;
        },
        setSupplierPaymentRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setSupplierPaymentPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedSupplierPayment(state, action) {
            state.selectedSupplierPayment = action.payload;
        },
        setSupplierPayment(state, action) {
            const data = action.payload;
            const rowIndex = state.supplierPaymentList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.supplierPaymentList[rowIndex] = data;
            }
        },
        setBillList(state, action) {
            state.billList = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedSupplierPayment, setSupplierPaymentRowPerPage, setSupplierPaymentPage, setBillList } = slice.actions;
// ----------------------------------------------------------------------

export function getSupplierPayments(data) {
    return async () => {
        try {
            const response = await axios.post('/supplier_payment/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setSupplierPayments(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getSupplierPayment(id) {
    return async () => {
        try {
            const response = await axios.post('/supplier_payment/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSupplierPayment(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveSupplierPayment(data) {
    return async function () {
        const response = await axios.post('supplier_payment/save', data);
        return response;
    };
}

export function updateSupplierPayment(data) {
    return async function () {
        const response = await axios.post('supplier_payment/update', data);
        return response;
    };
}

export function deleteSupplierPayment(data) {
    return async function () {
        const response = await axios.post('supplier_payment/delete', data);
        return response;
    };
}
export function getPurchaseBills(data) {
    return async () => {
        try {
            const response = await axios.post('/supplier_payment/search', data);
            if (response.data.status) {
                dispatch(slice.actions.setBillList(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
