// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    taxList: [],
    selectedTax: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'tax',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setTaxes(state, action) {
            state.taxList = action.payload;
        },
        setSelectedTax(state, action) {
            state.selectedTax = action.payload;
        },
        setTax(state, action) {
            const data = action.payload;
            const rowIndex = state.taxList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.taxList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedTax } = slice.actions;
// ----------------------------------------------------------------------

export function getTaxes() {
    return async () => {
        try {
            const response = await axios.post('/tax/list');
            if (response.data.status) {
                dispatch(slice.actions.setTaxes(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getTax(id) {
    return async () => {
        try {
            const response = await axios.post('/tax/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setTax(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveTax(data) {
    return async function () {
        const response = await axios.post('tax/save', data);
        return response;
    };
}

export function updateTax(data) {
    return async function () {
        const response = await axios.post('tax/update', data);
        return response;
    };
}

export function deleteTax(data) {
    return async function () {
        const response = await axios.post('tax/delete', data);
        return response;
    };
}
