// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    serviceCategoryList: [],
    selectedServiceCategory: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'serviceCategory',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setServiceCategories(state, action) {
            state.serviceCategoryList = action.payload;
        },
        setSelectedServiceCategory(state, action) {
            state.selectedServiceCategory = action.payload;
        },
        setServiceCategory(state, action) {
            const data = action.payload;
            const rowIndex = state.serviceCategoryList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.serviceCategoryList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedServiceCategory } = slice.actions;
// ----------------------------------------------------------------------

export function getServiceCategories() {
    return async () => {
        try {
            const response = await axios.post('/service-category/list');
            if (response.data.status) {
                dispatch(slice.actions.setServiceCategories(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getServiceCategory(id) {
    return async () => {
        try {
            const response = await axios.post('/service-category/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setServiceCategory(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveServiceCategory(data) {
    return async function () {
        const response = await axios.post('service-category/save', data);
        return response;
    };
}

export function updateServiceCategory(data) {
    return async function () {
        const response = await axios.post('service-category/update', data);
        return response;
    };
}

export function deleteServiceCategory(data) {
    return async function () {
        const response = await axios.post('service-category/delete', data);
        return response;
    };
}
