// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    customerList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedCustomer: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCustomers(state, action) {
            state.customerList = action.payload;
        },
        setCustomerRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setCustomerPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedCustomer(state, action) {
            state.selectedCustomer = action.payload;
        },
        setCustomer(state, action) {
            const data = action.payload;
            const rowIndex = state.customerList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.customerList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedCustomer, setCustomerRowPerPage, setCustomerPage } = slice.actions;
// ----------------------------------------------------------------------

export function getCustomers(data) {
    return async () => {
        try {
            const response = await axios.post('/customer/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setCustomers(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getCustomer(id) {
    return async () => {
        try {
            const response = await axios.post('/customer/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setCustomer(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveCustomer(data) {
    return async function () {
        const response = await axios.post('customer/save', data);
        return response;
    };
}

export function updateCustomer(data) {
    return async function () {
        const response = await axios.post('customer/update', data);
        return response;
    };
}

export function deleteCustomer(data) {
    return async function () {
        const response = await axios.post('customer/delete', data);
        return response;
    };
}
