// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    designationList: [],
    selectedDesignation: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setDesignations(state, action) {
            state.designationList = action.payload;
        },
        setSelectedDesignation(state, action) {
            state.selectedDesignation = action.payload;
        },
        setDesignation(state, action) {
            const data = action.payload;
            const rowIndex = state.designationList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.designationList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedDesignation } = slice.actions;
// ----------------------------------------------------------------------

export function getDesignations() {
    return async () => {
        try {
            const response = await axios.post('/designation/list');
            if (response.data.status) {
                dispatch(slice.actions.setDesignations(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getDesignation(id) {
    return async () => {
        try {
            const response = await axios.post('/designation/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setDesignation(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveDesignation(data) {
    return async function () {
        const response = await axios.post('designation/save', data);
        return response;
    };
}

export function updateDesignation(data) {
    return async function () {
        const response = await axios.post('designation/update', data);
        return response;
    };
}

export function deleteDesignation(data) {
    return async function () {
        const response = await axios.post('designation/delete', data);
        return response;
    };
}
