// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    transactionList: [],
    opening: 0,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'ledgerBook',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setTransaction(state, action) {
            state.transactionList = action.payload.vouchers;
            state.opening = action.payload.opening;
        }
    }
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getLedgerBookTransactions(data) {
    return async () => {
        try {
            const response = await axios.post('/ledger_book', data);
            if (response.data.status) {
                dispatch(slice.actions.setTransaction(response.data.data));
                dispatch(slice.actions.hasError(''));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
