// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    serviceList: [],
    selectedService: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setServices(state, action) {
            state.serviceList = action.payload;
        },
        setSelectedService(state, action) {
            state.selectedService = action.payload;
        },
        setService(state, action) {
            const data = action.payload;
            const rowIndex = state.serviceList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.serviceList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedService } = slice.actions;
// ----------------------------------------------------------------------

export function getServices() {
    return async () => {
        try {
            const response = await axios.post('/service/list');
            if (response.data.status) {
                dispatch(slice.actions.setServices(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getService(id) {
    return async () => {
        try {
            const response = await axios.post('/service/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setService(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveService(data) {
    return async function () {
        const response = await axios.post('service/save', data);
        return response;
    };
}

export function updateService(data) {
    return async function () {
        const response = await axios.post('service/update', data);
        return response;
    };
}

export function deleteService(data) {
    return async function () {
        const response = await axios.post('service/delete', data);
        return response;
    };
}
