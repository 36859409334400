// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    returnTypeList: [],
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'returnType',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setReturnTypes(state, action) {
            state.returnTypeList = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getReturnTypes() {
    return async () => {
        try {
            const response = await axios.post('/return_type/list');
            if (response.data.status) {
                dispatch(slice.actions.setReturnTypes(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
