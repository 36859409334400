// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg xmlns="http://www.w3.org/2000/svg" width="159" height="50" viewBox="0 0 209 63">
            <g id="Group_56" data-name="Group 56" transform="translate(-860 -509)">
                <text
                    id="Laundry"
                    transform="translate(860 559)"
                    fill="#fff"
                    fontSize="50"
                    fontFamily="Quicksand-Bold, Quicksand"
                    fontWeight="700"
                >
                    <tspan x="0" y="0">
                        Laundry
                    </tspan>
                </text>
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" transform="translate(1046 519)" fill="#fff" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="5.5" cy="5.5" r="5.5" transform="translate(1031 528)" fill="#fff" />
                <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(1047 515)" fill="none" stroke="#fff" strokeWidth="1">
                    <circle cx="11" cy="11" r="11" stroke="none" />
                    <circle cx="11" cy="11" r="10.5" fill="none" />
                </g>
            </g>
        </svg>
    );
};

export default Logo;
