// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    groupheadList: [],
    selectedGrouphead: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'grouphead',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setGroupheads(state, action) {
            state.groupheadList = action.payload;
        },
        setSelectedGrouphead(state, action) {
            state.selectedGrouphead = action.payload;
        },
        setGrouphead(state, action) {
            const data = action.payload;
            const rowIndex = state.groupheadList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.groupheadList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedGrouphead } = slice.actions;
// ----------------------------------------------------------------------

export function getGroupheads() {
    return async () => {
        try {
            const response = await axios.post('/grouphead/list');
            if (response.data.status) {
                dispatch(slice.actions.setGroupheads(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getGrouphead(id) {
    return async () => {
        try {
            const response = await axios.post('/grouphead/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setGrouphead(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveGrouphead(data) {
    return async function () {
        const response = await axios.post('grouphead/save', data);
        return response;
    };
}

export function updateGrouphead(data) {
    return async function () {
        const response = await axios.post('grouphead/update', data);
        return response;
    };
}

export function deleteGrouphead(data) {
    return async function () {
        const response = await axios.post('grouphead/delete', data);
        return response;
    };
}
