// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    companyData: {
        id: 0,
        name: '',
        address: '',
        street: '',
        city: '',
        post_code: '',
        contact_number: '',
        email: '',
        trn: '',
        logo: null
    },
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setCompany(state, action) {
            state.companyData = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompany() {
    return async () => {
        try {
            const response = await axios.post('/company');
            if (response.data.status) {
                dispatch(slice.actions.setCompany(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function updateCompany(data) {
    return async function () {
        const response = await axios.post('company/update', data);
        return response;
    };
}
