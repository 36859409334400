// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    couponList: [],
    selectedCoupon: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCoupons(state, action) {
            state.couponList = action.payload;
        },
        setSelectedCoupon(state, action) {
            state.selectedCoupon = action.payload;
        },
        setCoupon(state, action) {
            const data = action.payload;
            const rowIndex = state.couponList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.couponList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedCoupon } = slice.actions;
// ----------------------------------------------------------------------

export function getCoupons() {
    return async () => {
        try {
            const response = await axios.post('/coupon/list');
            if (response.data.status) {
                dispatch(slice.actions.setCoupons(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getCoupon(id) {
    return async () => {
        try {
            const response = await axios.post('/coupon/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setCoupon(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveCoupon(data) {
    return async function () {
        const response = await axios.post('coupon/save', data);
        return response;
    };
}

export function updateCoupon(data) {
    return async function () {
        const response = await axios.post('coupon/update', data);
        return response;
    };
}

export function deleteCoupon(data) {
    return async function () {
        const response = await axios.post('coupon/delete', data);
        return response;
    };
}
