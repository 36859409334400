// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    orderList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedOrder: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setOrders(state, action) {
            state.orderList = action.payload;
        },
        setOrderRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setOrderPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedOrder(state, action) {
            state.selectedOrder = action.payload;
        },
        setOrder(state, action) {
            const data = action.payload;
            const rowIndex = state.orderList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.orderList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedOrder, setOrderRowPerPage, setOrderPage } = slice.actions;
// ----------------------------------------------------------------------

export function getOrders(data) {
    return async () => {
        try {
            const response = await axios.post('/sale_order/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setOrders(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getOrder(id) {
    return async () => {
        try {
            const response = await axios.post('/sale_order/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSelectedOrder(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveOrder(data) {
    return async function () {
        const response = await axios.post('sale_order/save', data);
        return response;
    };
}

export function updateOrder(data) {
    return async function () {
        const response = await axios.post('sale_order/update', data);
        return response;
    };
}

export function deleteOrder(data) {
    return async function () {
        const response = await axios.post('sale_order/delete', data);
        return response;
    };
}
