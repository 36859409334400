// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    productItemList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedProductItem: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'productItem',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setProductItems(state, action) {
            state.productItemList = action.payload;
        },
        setProductRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setProductPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedProductItem(state, action) {
            state.selectedProductItem = action.payload;
        },
        setProductItem(state, action) {
            const data = action.payload;
            const rowIndex = state.productItemList.findIndex((productItem) => productItem.id === data.id);
            if (rowIndex) {
                state.productItemList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedProductItem, setProductRowPerPage, setProductPage } = slice.actions;
// ----------------------------------------------------------------------

export function getProductItems(data = {}) {
    return async () => {
        try {
            const response = await axios.post('/item/list', { ...data, stock_type: 'item' });
            if (response.data.status) {
                dispatch(slice.actions.setProductItems(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getProductItem(id) {
    return async () => {
        try {
            const response = await axios.post('/item/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setProductItem(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveProductItem(data) {
    return async function () {
        const response = await axios.post('item/save', data);
        return response;
    };
}

export function updateProductItem(data) {
    return async function () {
        const response = await axios.post('item/update', data);
        return response;
    };
}

export function deleteProductItem(data) {
    return async function () {
        const response = await axios.post('item/delete', data);
        return response;
    };
}
