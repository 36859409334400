// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    pickupList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedPickup: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'pickup',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPickups(state, action) {
            state.pickupList = action.payload;
        },
        setPickupRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setPickupPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedPickup(state, action) {
            state.selectedPickup = action.payload;
        },
        setPickup(state, action) {
            const data = action.payload;
            const rowIndex = state.pickupList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.pickupList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedPickup, setPickupRowPerPage, setPickupPage } = slice.actions;
// ----------------------------------------------------------------------

export function getPickups(data) {
    return async () => {
        try {
            const response = await axios.post('/pickup/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setPickups(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getPickup(id) {
    return async () => {
        try {
            const response = await axios.post('/pickup/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSelectedPickup(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function cancelPickup(data) {
    return async function () {
        const response = await axios.post('pickup/cancel', data);
        return response;
    };
}

export function moveToSchedule(data) {
    return async function () {
        const response = await axios.post('pickup/move-to-schedule', data);
        return response;
    };
}

export function changeStatus(data) {
    return async function () {
        const response = await axios.post('/pickup/change-status', data);
        return response;
    };
}
