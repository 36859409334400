// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    userList: [],
    deliveryBoyList: [],
    selectedUser: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setUsers(state, action) {
            state.userList = action.payload;
        },
        setDeliveryBoys(state, action) {
            state.deliveryBoyList = action.payload;
        },
        setSelectedUser(state, action) {
            state.selectedUser = action.payload;
        },
        setUser(state, action) {
            const data = action.payload;
            const rowIndex = state.userList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.userList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedUser } = slice.actions;
// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        try {
            const response = await axios.post('/user/list');
            if (response.data.status) {
                dispatch(slice.actions.setUsers(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getUser(id) {
    return async () => {
        try {
            const response = await axios.post('/user/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setUser(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveUser(data) {
    return async function () {
        const response = await axios.post('user/save', data);
        return response;
    };
}

export function updateUser(data) {
    return async function () {
        const response = await axios.post('user/update', data);
        return response;
    };
}

export function deleteUser(data) {
    return async function () {
        const response = await axios.post('user/delete', data);
        return response;
    };
}

export function getDeliveryBoys() {
    return async () => {
        try {
            const response = await axios.post('deliveryboy/list');
            if (response.data.status) {
                dispatch(slice.actions.setDeliveryBoys(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
