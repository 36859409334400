// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    receiptList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedReceipt: null,
    message: null,
    status: false,
    nextBillNo: 0,
    error: ''
};

const slice = createSlice({
    name: 'receipt',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setReceipts(state, action) {
            state.receiptList = action.payload;
        },
        setReceiptRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setReceiptPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedReceipt(state, action) {
            state.selectedReceipt = action.payload;
            if (action.payload) state.nextBillNo = action.payload.receipt_number;
            else state.nextBillNo = 0;
        },
        setReceipt(state, action) {
            const data = action.payload;
            const rowIndex = state.receiptList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.receiptList[rowIndex] = data;
            }
        },
        setBillNumber(state, action) {
            state.nextBillNo = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedReceipt, setReceiptRowPerPage, setReceiptPage } = slice.actions;
// ----------------------------------------------------------------------

export function getReceipts(data) {
    return async () => {
        try {
            const response = await axios.post('/receipt/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setReceipts(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getReceipt(id) {
    return async () => {
        try {
            const response = await axios.post('/receipt/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setReceipt(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveReceipt(data) {
    return async function () {
        const response = await axios.post('receipt/save', data);
        return response;
    };
}

export function updateReceipt(data) {
    return async function () {
        const response = await axios.post('receipt/update', data);
        return response;
    };
}

export function deleteReceipt(data) {
    return async function () {
        const response = await axios.post('receipt/delete', data);
        return response;
    };
}

export function nextReceiptNumber(data) {
    return async () => {
        try {
            const response = await axios.post('receipt/next_number', data);
            if (response.data.status) {
                dispatch(slice.actions.setBillNumber(response.data.bill_number));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function closingBalance(data) {
    return async function () {
        const response = await axios.post('closing_balance', data);
        return response;
    };
}
