// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    transactionList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'dayBook',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setTransaction(state, action) {
            state.transactionList = action.payload;
        },
        setDayTransactionRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setDayTransactionPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { setDayTransactionRowPerPage, setDayTransactionPage } = slice.actions;
// ----------------------------------------------------------------------

export function getDayBookTransactions(data) {
    return async () => {
        try {
            const response = await axios.post('/day_book', data);
            if (response.data.status) {
                dispatch(slice.actions.setTransaction(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
