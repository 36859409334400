import axios from 'axios';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

const serviceToken = window.localStorage.getItem('serviceToken');

if (serviceToken) {
    axiosServices.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
}

axiosServices.defaults.headers.common.Accept = 'application/json';
// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
