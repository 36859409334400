// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    SaleOrderReportList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedPickup: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'saleOrderReport',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSaleOrderReports(state, action) {
            state.SaleOrderReportList = action.payload;
        },
        setSaleOrderReportRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setSaleOrderReportPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedSaleOrderReport(state, action) {
            state.selectedSaleOrderReport = action.payload;
        },
        setSaleOrderReport(state, action) {
            const data = action.payload;
            const rowIndex = state.SaleOrderReportList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.SaleOrderReportList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedSaleOrderReport, setSaleOrderReportRowPerPage, setSaleOrderReportPage } = slice.actions;
// ----------------------------------------------------------------------

export function getSaleOrderReports(data) {
    return async () => {
        try {
            const response = await axios.post('/reports/order-report', data);
            if (response.data.status) {
                dispatch(slice.actions.setSaleOrderReports(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
