// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    saleList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedSale: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'sale',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSales(state, action) {
            state.saleList = action.payload;
        },
        setSaleRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setSalePage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedSale(state, action) {
            state.selectedSale = action.payload;
        },
        setSale(state, action) {
            const data = action.payload;
            const rowIndex = state.saleList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.saleList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedSale, setSaleRowPerPage, setSalePage } = slice.actions;
// ----------------------------------------------------------------------

export function getSales(data) {
    return async () => {
        try {
            const response = await axios.post('/sale/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setSales(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getSale(id) {
    return async () => {
        try {
            const response = await axios.post('/sale/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSelectedSale(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveSale(data) {
    return async function () {
        const response = await axios.post('sale/save', data);
        return response;
    };
}

export function updateSale(data) {
    return async function () {
        const response = await axios.post('sale/update', data);
        return response;
    };
}

export function deleteSale(data) {
    return async function () {
        const response = await axios.post('sale/delete', data);
        return response;
    };
}

export function changeBillStatus(data) {
    return async function () {
        const response = await axios.post('sale/change-status', data);
        return response;
    };
}

export function saleToSchedule(data) {
    return async function () {
        const response = await axios.post('sale/sale-to-schedule', data);
        return response;
    };
}
