// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    unitList: [],
    selectedUnit: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setUnits(state, action) {
            state.unitList = action.payload;
        },
        setSelectedUnit(state, action) {
            state.selectedUnit = action.payload;
        },
        setUnit(state, action) {
            const data = action.payload;
            const rowIndex = state.unitList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.unitList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedUnit } = slice.actions;
// ----------------------------------------------------------------------

export function getUnits() {
    return async () => {
        try {
            const response = await axios.post('/unit/list');
            if (response.data.status) {
                dispatch(slice.actions.setUnits(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getUnit(id) {
    return async () => {
        try {
            const response = await axios.post('/unit/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setUnit(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveUnit(data) {
    return async function () {
        const response = await axios.post('unit/save', data);
        return response;
    };
}

export function updateUnit(data) {
    return async function () {
        const response = await axios.post('unit/update', data);
        return response;
    };
}

export function deleteUnit(data) {
    return async function () {
        const response = await axios.post('unit/delete', data);
        return response;
    };
}
