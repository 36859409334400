import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import axios from 'utils/axios';
// action - state management
import { LOGIN, LOGOUT, BRANCH_CHANGE, UPDATE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    company: null,
    branch: null,
    functionalSetting: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const ServerAuthContext = createContext(null);

export const ServerAuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');

                if (serviceToken != null) {
                    const company = JSON.parse(window.localStorage.getItem('company'));
                    const user = JSON.parse(window.localStorage.getItem('user'));
                    const functionalSetting = JSON.parse(window.localStorage.getItem('functional_setting'));
                    const branch = JSON.parse(window.localStorage.getItem('branch'));
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                            company,
                            functionalSetting,
                            branch
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT
                });
            }
        };
        init();
    }, []);
    const UserEmailPasswordSignIn = function (email, password) {
        return axios.post('/login', { email, password });
    };
    const setSession = (serviceToken) => {
        if (serviceToken) {
            localStorage.setItem('serviceToken', serviceToken);
            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        } else {
            localStorage.removeItem('serviceToken');
            delete axios.defaults.headers.common.Authorization;
        }
    };
    const setLoginResponse = (data) => {
        setSession(data.token);
        localStorage.setItem('company', JSON.stringify(data.company));
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('functional_setting', JSON.stringify(data.functional_setting));
        let branch = null;
        if (data.user.user_branches.length > 0) {
            branch = data.user.user_branches[0].branch;
        }
        localStorage.setItem('branch', JSON.stringify(branch));
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: data.user,
                company: data.company,
                functionalSetting: data.functional_setting,
                token: data.token,
                branch,
                error: false
            }
        });
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };
    const onChangeBranch = (branch) => {
        localStorage.setItem('branch', JSON.stringify(branch));
        dispatch({ type: BRANCH_CHANGE, payload: branch });
    };
    const resetPassword = async (email) => {
        localStorage.setItem('forget-mail', email);
        const response = await axios.post('forgot/password', { email });
        return response;
    };
    const verifyOtp = async (data) => {
        const response = await axios.post('/verify/otp', data);
        return response;
    };

    const otpVerificationSuccess = (data) => {
        localStorage.removeItem('forget-mail');
        localStorage.setItem('forget-email-token', data.token);
    };

    const changePassword = async (data) => {
        const tokenStr = localStorage.getItem('forget-email-token');
        const response = await axios.post('/change/password', data, { headers: { Authorization: `Bearer ${tokenStr}` } });
        return response;
    };
    const updateProfile = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
        dispatch({ type: UPDATE, payload: user });
    };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <ServerAuthContext.Provider
            value={{
                ...state,
                UserEmailPasswordSignIn,
                login: () => {},
                logout,
                resetPassword,
                updateProfile,
                setLoginResponse,
                otpVerificationSuccess,
                verifyOtp,
                changePassword,
                onChangeBranch
            }}
        >
            {children}
        </ServerAuthContext.Provider>
    );
};

ServerAuthProvider.propTypes = {
    children: PropTypes.node
};

export default ServerAuthContext;
