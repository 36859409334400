// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    bagList: [],
    selectedBag: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'bag',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setBags(state, action) {
            state.bagList = action.payload;
        },
        setSelectedBag(state, action) {
            state.selectedBag = action.payload;
        },
        setBag(state, action) {
            const data = action.payload;
            const rowIndex = state.bagList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.bagList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedBag } = slice.actions;
// ----------------------------------------------------------------------

export function getBags() {
    return async () => {
        try {
            const response = await axios.post('/bag/list');
            if (response.data.status) {
                dispatch(slice.actions.setBags(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getBag(id) {
    return async () => {
        try {
            const response = await axios.post('/bag/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setBag(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveBag(data) {
    return async function () {
        const response = await axios.post('bag/save', data);
        return response;
    };
}

export function updateBag(data) {
    return async function () {
        const response = await axios.post('bag/update', data);
        return response;
    };
}

export function deleteBag(data) {
    return async function () {
        const response = await axios.post('bag/delete', data);
        return response;
    };
}
