import { useEffect, useRef, useState } from 'react';

// material-ui
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';
import useConfig from 'hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { IconSettings } from '@tabler/icons';
import { dispatch } from 'store';
import { openDrawer, openHeader } from 'store/slices/menu';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = () => {
    const { borderRadius, locale, onChangeLocale } = useConfig();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [language, setLanguage] = useState(locale);

    const handleListItemClick = (event, lng) => {
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        dispatch(openDrawer(false));
        dispatch(openHeader(false));
        navigate('/pos');
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    return (
        <>
            <Chip
                sx={{
                    height: '38px',
                    alignItems: 'center',
                    borderRadius: '10px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    color: 'blue',
                    fontSize: 18,
                    backgroundColor: theme.palette.mode === 'white' ? theme.palette.dark.main : theme.palette.primary.light,
                    mr: 2
                }}
                icon={
                    <PointOfSaleIcon
                        // src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label="POS"
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
            />
        </>
    );
};

export default LocalizationSection;
