import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, IconButton, Paper, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer, openHeader } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'headerOpen' })(({ theme, open, headerOpen }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    }),
    ...(!headerOpen && { margin: 0 })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen, headerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            {headerOpen && (
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: drawerOpen ? theme.transitions.create('width') : 'none'
                    }}
                >
                    {header}
                </AppBar>
            )}

            {/* drawer */}
            <Sidebar />
            {/* main content */}
            <Main theme={theme} open={drawerOpen} headerOpen={headerOpen}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </>
                )}
            </Main>
            {headerOpen ? (
                <Paper
                    elevation={3}
                    style={{
                        position: 'fixed',
                        bottom: 5,
                        right: 5,
                        padding: '5px',
                        zIndex: 5000,
                        width: '80px'
                    }}
                >
                    <IconButton
                        color="primary"
                        aria-label="exit full screen"
                        component="label"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            dispatch(openDrawer(false));
                            dispatch(openHeader(false));
                        }}
                    >
                        <FullscreenIcon fontSize="large" />
                    </IconButton>
                </Paper>
            ) : (
                <Paper
                    elevation={3}
                    style={{
                        position: 'fixed',
                        bottom: 5,
                        right: 5,
                        padding: '5px',
                        zIndex: 50,
                        width: '80px'
                    }}
                >
                    <IconButton
                        color="primary"
                        aria-label="exit full screen"
                        component="label"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            dispatch(openDrawer(true));
                            dispatch(openHeader(true));
                        }}
                    >
                        <FullscreenExitRoundedIcon fontSize="large" />
                    </IconButton>
                </Paper>
            )}
        </Box>
    );
};

export default MainLayout;
