import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
import StoreIcon from '@mui/icons-material/Store';

// ==============================|| LOCALIZATION ||============================== //

const BranchSection = () => {
    const { borderRadius } = useConfig();

    const { user, branch, onChangeBranch } = useAuth();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [currentBranch, setBranch] = useState(branch);
    const handleListItemClick = (event, branch) => {
        setBranch(branch);
        onChangeBranch(branch);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setBranch(branch);
    }, [branch]);
    return (
        <>
            <Box
                sx={{
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    }
                }}
            >
                <Chip
                    sx={{
                        height: '38px',
                        alignItems: 'center',
                        borderRadius: '10px',
                        transition: 'all .2s ease-in-out',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: 'white',
                        fontSize: 18,
                        // backgroundColor: theme.palette.mode === 'white' ? theme.palette.dark.main : theme.palette.primary.light,
                        mr: 2
                    }}
                    icon={
                        <StoreIcon
                            // src={User1}
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="inherit"
                        />
                    }
                    label={currentBranch?.name}
                    variant="outlined"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="primary"
                />
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            minWidth: 200,
                                            maxWidth: 280,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250
                                            }
                                        }}
                                    >
                                        {user.user_branches.map((userBranch) => (
                                            <ListItemButton
                                                selected={currentBranch === userBranch.branch}
                                                onClick={(event) => handleListItemClick(event, userBranch.branch)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Typography color="textPrimary">{userBranch.branch.name}</Typography>
                                                            {/* <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                                (UK)
                                                            </Typography> */}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default BranchSection;
