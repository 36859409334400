// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    customerOutstandingList: [],
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'customerOutStanding',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setOutstandings(state, action) {
            state.customerOutstandingList = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getCustomerOustandings(data) {
    return async () => {
        try {
            const response = await axios.post('/reports/customer_outstanding', data);
            if (response.data.status) {
                dispatch(slice.actions.setOutstandings(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
