// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    priceList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedPriceList: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'priceList',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPriceLists(state, action) {
            state.priceList = action.payload;
        },
        setPriceListRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setPriceListPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedPriceList(state, action) {
            state.selectedPriceList = action.payload;
        },
        setPriceList(state, action) {
            const data = action.payload;
            const rowIndex = state.priceList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.priceList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedPriceList, setPriceListRowPerPage, setPriceListPage } = slice.actions;
// ----------------------------------------------------------------------

export function getPriceLists(data) {
    return async () => {
        try {
            const response = await axios.post('/item_price/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setPriceLists(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
// export function getPriceList(id) {
//     return async () => {
//         try {
//             const response = await axios.post('/item_price/details', { id });
//             if (response.data.status) {
//                 dispatch(slice.actions.setPriceList(response.data.data));
//             } else {
//                 dispatch(slice.actions.hasError(response.data.message));
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     };
// }
export function savePriceList(data) {
    return async function () {
        const response = await axios.post('item_price/save', data);
        return response;
    };
}

export function updatePriceList(data) {
    return async function () {
        const response = await axios.post('item_price/update', data);
        return response;
    };
}

export function deletePriceList(data) {
    return async function () {
        const response = await axios.post('item_price/delete', data);
        return response;
    };
}
