import Dashboard from './dashboard';
import Inventory from './inventory';
import Account from './account';

// ==============================|| MENU ITEMS ||============================== //
function MenuItem() {
    const inventory = Inventory();
    const account = Account();
    const dashboard = Dashboard();
    const menuItems = {
        items: [dashboard, inventory, account]
    };
    return menuItems;
}

export default MenuItem;
