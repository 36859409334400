// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    departmentList: [],
    selectedDepartment: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setDepartments(state, action) {
            state.departmentList = action.payload;
        },
        setSelectedDepartment(state, action) {
            state.selectedDepartment = action.payload;
        },
        setDepartment(state, action) {
            const data = action.payload;
            const rowIndex = state.departmentList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.departmentList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedDepartment } = slice.actions;
// ----------------------------------------------------------------------

export function getDepartments() {
    return async () => {
        try {
            const response = await axios.post('/department/list');
            if (response.data.status) {
                dispatch(slice.actions.setDepartments(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getDepartment(id) {
    return async () => {
        try {
            const response = await axios.post('/department/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setDepartment(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveDepartment(data) {
    return async function () {
        const response = await axios.post('department/save', data);
        return response;
    };
}

export function updateDepartment(data) {
    return async function () {
        const response = await axios.post('department/update', data);
        return response;
    };
}

export function deleteDepartment(data) {
    return async function () {
        const response = await axios.post('department/delete', data);
        return response;
    };
}
