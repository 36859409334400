// third-party
import { FormattedMessage } from 'react-intl';
// assets
import { IconBasket, IconCalendar, IconFile } from '@tabler/icons';
import useAuth from 'hooks/useAuth';

// constant
const icons = {
    IconBasket,
    IconCalendar,
    IconFile
};
//
// ==============================|| APPLICATION MENU ITEMS ||============================== //

function Account() {
    const { user } = useAuth();
    let permissions = [];
    if (user) {
        permissions = user.user_role.permissions.map((permission) => permission.name);
    }

    let masterChildren = [
        {
            permission: 'account.ledger.list',
            id: 'ledger',
            title: <FormattedMessage id="ledger" />,
            type: 'item',
            url: 'account/ledger',
            breadcrumbs: true
        }
    ];
    masterChildren = masterChildren.filter((item) => permissions.includes(item.permission));
    let transactionChilden = [
        {
            permission: 'account.payment.list',
            id: 'payment',
            title: <FormattedMessage id="payment" />,
            type: 'item',
            url: '/account/payment',
            breadcrumbs: false
        },
        {
            permission: 'account.receipt.list',
            id: 'receipt',
            title: <FormattedMessage id="receipt" />,
            type: 'item',
            url: '/account/receipt',
            breadcrumbs: false
        }
    ];
    transactionChilden = transactionChilden.filter((item) => permissions.includes(item.permission));

    let reportChildren = [
        {
            permission: 'account.day_book.list',
            id: 'day_book',
            title: <FormattedMessage id="day_book" />,
            type: 'item',
            url: '/account/day-book',
            breadcrumbs: true
        },
        {
            permission: 'account.ledger_book.list',
            id: 'ledger_book',
            title: <FormattedMessage id="ledger_book" />,
            type: 'item',
            url: '/account/ledger-book',
            breadcrumbs: true
        }
    ];
    reportChildren = reportChildren.filter((item) => permissions.includes(item.permission));
    return {
        id: 'accounts',
        title: <FormattedMessage id="accounts" />,
        type: 'group',
        children: [
            {
                id: 'master',
                title: <FormattedMessage id="master" />,
                type: 'collapse',
                icon: icons.IconBasket,
                children: masterChildren
            },
            {
                id: 'transaction',
                title: <FormattedMessage id="transaction" />,
                type: 'collapse',
                icon: icons.IconCalendar,
                children: transactionChilden
            },
            {
                id: 'books',
                title: <FormattedMessage id="books" />,
                type: 'collapse',
                icon: icons.IconFile,
                children: reportChildren
            }
        ]
    };
}

export default Account;
