// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    paymentList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedPayment: null,
    message: null,
    status: false,
    nextBillNo: 0,
    error: ''
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPayments(state, action) {
            state.paymentList = action.payload;
        },
        setPaymentRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setPaymentPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedPayment(state, action) {
            state.selectedPayment = action.payload;
            if (action.payload) state.nextBillNo = action.payload.payment_number;
            else state.nextBillNo = 0;
        },
        setPayment(state, action) {
            const data = action.payload;
            const rowIndex = state.paymentList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.paymentList[rowIndex] = data;
            }
        },
        setBillNumber(state, action) {
            state.nextBillNo = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedPayment, setPaymentRowPerPage, setPaymentPage } = slice.actions;
// ----------------------------------------------------------------------

export function getPayments(data) {
    return async () => {
        try {
            const response = await axios.post('/payment/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setPayments(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getPayment(id) {
    return async () => {
        try {
            const response = await axios.post('/payment/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setPayment(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function savePayment(data) {
    return async function () {
        const response = await axios.post('payment/save', data);
        return response;
    };
}

export function updatePayment(data) {
    return async function () {
        const response = await axios.post('payment/update', data);
        return response;
    };
}

export function deletePayment(data) {
    return async function () {
        const response = await axios.post('payment/delete', data);
        return response;
    };
}

export function nextPaymentNumber(data) {
    return async () => {
        try {
            const response = await axios.post('payment/next_number', data);
            if (response.data.status) {
                dispatch(slice.actions.setBillNumber(response.data.bill_number));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export function closingBalance(data) {
    return async function () {
        const response = await axios.post('closing_balance', data);
        return response;
    };
}
