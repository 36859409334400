// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    pickupReportList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedPickup: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'pickupReport',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPickupReports(state, action) {
            state.pickupReportList = action.payload;
        },
        setPickupReportRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setPickupReportPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedPickupReport(state, action) {
            state.selectedPickupReport = action.payload;
        },
        setPickupReport(state, action) {
            const data = action.payload;
            const rowIndex = state.pickupReportList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.pickupReportList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedPickupReport, setPickupReportRowPerPage, setPickupReportPage } = slice.actions;
// ----------------------------------------------------------------------

export function getPickupReports(data) {
    return async () => {
        try {
            const response = await axios.post('/reports/pickup-report', data);
            if (response.data.status) {
                dispatch(slice.actions.setPickupReports(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
