import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// masters
const CompanyDetails = Loadable(lazy(() => import('views/masters/company')));
const BranchDetails = Loadable(lazy(() => import('views/masters/branch')));
const TaxDetails = Loadable(lazy(() => import('views/masters/tax')));
const CategoryDetails = Loadable(lazy(() => import('views/masters/category')));
const CreaseDetails = Loadable(lazy(() => import('views/masters/crease')));
const DepartmentDetails = Loadable(lazy(() => import('views/masters/department')));
const DesignationDetails = Loadable(lazy(() => import('views/masters/designation')));
const ModeOfPayDetails = Loadable(lazy(() => import('views/masters/modeofpay')));
const CustomerDetails = Loadable(lazy(() => import('views/masters/customer')));
const SupplierDetails = Loadable(lazy(() => import('views/masters/supplier')));
const ServiceDetails = Loadable(lazy(() => import('views/masters/service')));
const ServiceCategoryDetails = Loadable(lazy(() => import('views/masters/service_category')));
const UserRole = Loadable(lazy(() => import('views/masters/user_role')));
const PriceGroup = Loadable(lazy(() => import('views/masters/price_group')));
const ItemDetails = Loadable(lazy(() => import('views/masters/item')));
const PriceList = Loadable(lazy(() => import('views/masters/price_list')));
// sample page routing
const Pos = Loadable(lazy(() => import('views/transactions/pos')));
const Order = Loadable(lazy(() => import('views/transactions/order')));
const Sale = Loadable(lazy(() => import('views/transactions/sale')));
const EditSale = Loadable(lazy(() => import('views/transactions/sale/edit')));
const Purchase = Loadable(lazy(() => import('views/transactions/purchase')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const UserDetails = Loadable(lazy(() => import('views/masters/user')));
const ProfileDetails = Loadable(lazy(() => import('views/masters/profile')));
const SaleQuotation = Loadable(lazy(() => import('views/transactions/sale_quotation')));
const StockList = Loadable(lazy(() => import('views/reports/stock_list')));
const PickupList = Loadable(lazy(() => import('views/reports/pickup')));
const PickupReportList = Loadable(lazy(() => import('views/reports/pickup_report')));
const DailySales = Loadable(lazy(() => import('views/reports/daily_sales')));
const ScheduleList = Loadable(lazy(() => import('views/reports/schedule')));
const ScheduleDeliveyList = Loadable(lazy(() => import('views/reports/schedule_delivery')));
const BagScheduleList = Loadable(lazy(() => import('views/reports/bag_schedule')));
const OfferBannerDetails = Loadable(lazy(() => import('views/masters/offer_banner')));
const BagDetails = Loadable(lazy(() => import('views/masters/bag')));
const PriceListMobile = Loadable(lazy(() => import('views/masters/price_list_mobile')));
const CouponDetails = Loadable(lazy(() => import('views/masters/coupon')));
const SupplierPaymentDetails = Loadable(lazy(() => import('views/transactions/supplier_payment')));
const LedgerDetails = Loadable(lazy(() => import('views/accounts/ledger')));
const PaymentDetails = Loadable(lazy(() => import('views/accounts/payment')));
const ReceiptDetails = Loadable(lazy(() => import('views/accounts/receipt')));
const ScheduleReportList = Loadable(lazy(() => import('views/reports/schedule_report')));
const SaleOrderReportList = Loadable(lazy(() => import('views/reports/sale_order')));
const DayBookDetails = Loadable(lazy(() => import('views/accounts/day_book')));
const DailyCollectionDetails = Loadable(lazy(() => import('views/reports/daily_collection')));
const LedgerBookDetails = Loadable(lazy(() => import('views/accounts/ledger_book')));
const CustomerOutstandingDetails = Loadable(lazy(() => import('views/reports/customer_outstanding')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },

        {
            path: '/masters/company',
            element: <CompanyDetails />
        },
        {
            path: '/masters/branch',
            element: <BranchDetails />
        },
        {
            path: '/masters/tax',
            element: <TaxDetails />
        },
        {
            path: '/masters/category',
            element: <CategoryDetails />
        },
        {
            path: '/masters/crease',
            element: <CreaseDetails />
        },
        {
            path: '/masters/department',
            element: <DepartmentDetails />
        },
        {
            path: '/masters/designation',
            element: <DesignationDetails />
        },
        {
            path: '/masters/modeofpay',
            element: <ModeOfPayDetails />
        },
        {
            path: '/masters/customer',
            element: <CustomerDetails />
        },
        {
            path: '/masters/supplier',
            element: <SupplierDetails />
        },
        {
            path: '/masters/service',
            element: <ServiceDetails />
        },
        {
            path: '/masters/service-category',
            element: <ServiceCategoryDetails />
        },
        {
            path: '/masters/user-role',
            element: <UserRole />
        },
        {
            path: '/masters/price-group',
            element: <PriceGroup />
        },
        {
            path: '/masters/item',
            element: <ItemDetails />
        },
        {
            path: '/masters/price-list',
            element: <PriceList />
        },
        {
            path: '/pos',
            element: <Pos />
        },
        {
            path: '/order',
            element: <Order />
        },
        {
            path: '/sale',
            element: <Sale />
        },
        {
            path: '/edit_sale',
            element: <EditSale />
        },
        {
            path: '/purchase',
            element: <Purchase />
        },
        {
            path: '/masters/user',
            element: <UserDetails />
        },
        {
            path: '/masters/profile',
            element: <ProfileDetails />
        },
        {
            path: '/sale-quotation',
            element: <SaleQuotation />
        },
        {
            path: 'reports/stock-list',
            element: <StockList />
        },
        {
            path: 'reports/picks',
            element: <PickupList />
        },
        {
            path: 'reports/daily-sales',
            element: <DailySales />
        },
        {
            path: '/reports/pickup-report',
            element: <PickupReportList />
        },
        {
            path: 'reports/schedule',
            element: <ScheduleList />
        },
        {
            path: 'reports/bag-schedule',
            element: <BagScheduleList />
        },
        {
            path: 'masters/offer-banner',
            element: <OfferBannerDetails />
        },
        {
            path: 'masters/bag',
            element: <BagDetails />
        },
        {
            path: 'mobile/price-list',
            element: <PriceListMobile />
        },
        {
            path: 'mobile/coupon',
            element: <CouponDetails />
        },
        {
            path: 'supplier-payment',
            element: <SupplierPaymentDetails />
        },
        {
            path: 'account/ledger',
            element: <LedgerDetails />
        },
        {
            path: 'account/payment',
            element: <PaymentDetails />
        },
        {
            path: 'account/receipt',
            element: <ReceiptDetails />
        },
        {
            path: '/reports/schedule-report',
            element: <ScheduleReportList />
        },
        {
            path: '/reports/sale-order',
            element: <SaleOrderReportList />
        },
        {
            path: 'account/day-book',
            element: <DayBookDetails />
        },
        {
            path: 'reports/daily-collection',
            element: <DailyCollectionDetails />
        },
        {
            path: 'account/ledger-book',
            element: <LedgerBookDetails />
        },
        {
            path: 'reports/customer-outstanding',
            element: <CustomerOutstandingDetails />
        },
        {
            path: 'delivery-schedules',
            element: <ScheduleDeliveyList />
        }
    ]
};

export default MainRoutes;
