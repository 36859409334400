// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import companyReducer from './slices/company';
import branchReducer from './slices/branch';
import categoryReducer from './slices/category';
import departmentReducer from './slices/department';
import taxReducer from './slices/tax';
import designationReducer from './slices/designation';
import modeofpayReducer from './slices/modeofpay';
import ledgerReducer from './slices/ledger';
import customerReducer from './slices/customer';
import priceGroupReducer from './slices/priceGroup';
import userRoleReducer from './slices/userRole';
import supplierReducer from './slices/supplier';
import serviceReducer from './slices/service';
import priceListReducer from './slices/priceList';
import serviceItemReducer from './slices/serviceItem';
import productItemReducer from './slices/productItem';
import unitReducer from './slices/unit';
import returnTypeReducer from './slices/returnType';
import orderReducer from './slices/order';
import userReducer from './slices/user';
import saleReducer from './slices/sale';
import purchaseReducer from './slices/purchase';
import saleQuotationReducer from './slices/saleQuotation';
import creaseReducer from './slices/crease';
import pickupReducer from './slices/pickup';
import scheduleReducer from './slices/schedule';
import serviceCategoryReducer from './slices/serviceCategory';
import offerBannerReducer from './slices/offerBanner';
import bagReducer from './slices/bag';
import priceListMobileReducer from './slices/priceListMobile';
import couponReducer from './slices/coupon';
import supplierPaymentReducer from './slices/supplier_payment';
import dailySalesReducer from './slices/dailySalesReport';
import GroupheadReducer from './slices/grouphead';
import PaymentReducer from './slices/payment';
import ReceiptReducer from './slices/receipt';
import DayBookReducer from './slices/dayBook';
import PickupReportReducer from './slices/pickupReport';
import dailyCollectionReportReducer from './slices/dailyCollectionReport';
import ScheduleReportReducer from './slices/scheduleReport';
import SaleOrderReportReducer from './slices/saleOrderReport';
import LedgerBooKReducer from './slices/ledgerBook';
import customerOutstandingReducer from './slices/customerOutstanding';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    company: companyReducer,
    branch: branchReducer,
    category: categoryReducer,
    department: departmentReducer,
    tax: taxReducer,
    designation: designationReducer,
    modeofpay: modeofpayReducer,
    ledger: ledgerReducer,
    customer: customerReducer,
    priceGroup: priceGroupReducer,
    userRole: userRoleReducer,
    supplier: supplierReducer,
    service: serviceReducer,
    priceList: priceListReducer,
    serviceItem: serviceItemReducer,
    productItem: productItemReducer,
    unit: unitReducer,
    returnType: returnTypeReducer,
    order: orderReducer,
    user: userReducer,
    sale: saleReducer,
    purchase: purchaseReducer,
    saleQuotation: saleQuotationReducer,
    crease: creaseReducer,
    pickup: pickupReducer,
    schedule: scheduleReducer,
    serviceCategory: serviceCategoryReducer,
    offerBanner: offerBannerReducer,
    bag: bagReducer,
    priceListMobile: priceListMobileReducer,
    coupon: couponReducer,
    supplierPayment: supplierPaymentReducer,
    dailySalesReport: dailySalesReducer,
    grouphead: GroupheadReducer,
    payment: PaymentReducer,
    receipt: ReceiptReducer,
    dayBook: DayBookReducer,
    pickupreport: PickupReportReducer,
    saleorderreport: SaleOrderReportReducer,
    dailyCollectionReport: dailyCollectionReportReducer,
    schedulereport: ScheduleReportReducer,
    ledgerBook: LedgerBooKReducer,
    customerOutStanding: customerOutstandingReducer
});

export default reducer;
