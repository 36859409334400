// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconMap2, IconChecklist, IconTruckDelivery, IconTrolley, IconPaperBag } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconMap2,
    IconChecklist,
    IconTruckDelivery,
    IconTrolley,
    IconPaperBag
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
function Dashboard() {
    const { user } = useAuth();
    let permissions = [];
    if (user) {
        permissions = user.user_role.permissions.map((permission) => permission.name);
    }
    let childran = [
        {
            permission: 'dashboard.pickup.list',
            id: 'picks',
            title: <FormattedMessage id="picks" />,
            type: 'item',
            url: '/reports/picks',
            icon: icons.IconMap2,
            breadcrumbs: false
        },
        {
            permission: 'dashboard.schedule.list',
            id: 'schedules',
            title: <FormattedMessage id="schedule" />,
            type: 'item',
            url: '/reports/schedule',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        // {
        //     permission: 'dashboard.schedule.list',
        //     id: 'bag_schedule',
        //     title: <FormattedMessage id="bag_schedule" />,
        //     type: 'item',
        //     url: '/reports/bag-schedule',
        //     icon: icons.IconChecklist,
        //     breadcrumbs: false
        // },
        {
            permission: 'dashboard.sale_order.list',
            id: 'order_list',
            title: <FormattedMessage id="order_list" />,
            type: 'item',
            url: '/order',
            icon: icons.IconTrolley,
            breadcrumbs: false
        },
        {
            permission: 'dashboard.sale.list',
            id: 'sale_list',
            title: <FormattedMessage id="sale_list" />,
            type: 'item',
            url: '/sale',
            icon: icons.IconPaperBag,
            breadcrumbs: false
        },
        {
            permission: 'dashboard.delivery_schedule.list',
            id: 'delivery_schedule',
            title: <FormattedMessage id="delivery_schedule" />,
            type: 'item',
            url: '/delivery-schedules',
            icon: icons.IconTruckDelivery,
            breadcrumbs: false
        }
    ];
    childran = childran.filter((item) => permissions.includes(item.permission));
    return {
        id: 'dashboard',
        title: <FormattedMessage id="dashboard" />,
        type: 'group',
        children: [
            {
                id: 'default',
                title: <FormattedMessage id="default" />,
                type: 'item',
                url: '/dashboard/default',
                icon: icons.IconDashboard,
                breadcrumbs: false
            },
            ...childran
        ]
    };
}

export default Dashboard;
