// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    saleQuotationList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedSaleQuotation: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'saleQuotation',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSaleQuotations(state, action) {
            state.saleQuotationList = action.payload;
        },
        setSaleQuotationRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setSaleQuotationPage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedSaleQuotation(state, action) {
            state.selectedSaleQuotation = action.payload;
        },
        setSaleQuotation(state, action) {
            const data = action.payload;
            const rowIndex = state.saleQuotationList.findIndex((item) => item.id === data.id);
            if (rowIndex) {
                state.saleQuotationList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedSaleQuotation, setSaleQuotationRowPerPage, setSaleQuotationPage } = slice.actions;
// ----------------------------------------------------------------------

export function getSaleQuotations(data) {
    return async () => {
        try {
            const response = await axios.post('/quotation/list', data);
            if (response.data.status) {
                dispatch(slice.actions.setSaleQuotations(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getSaleQuotation(id) {
    return async () => {
        try {
            const response = await axios.post('/quotation/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setSelectedSaleQuotation(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveSaleQuotation(data) {
    return async function () {
        const response = await axios.post('quotation/save', data);
        return response;
    };
}

export function updateSaleQuotation(data) {
    return async function () {
        const response = await axios.post('quotation/update', data);
        return response;
    };
}

export function deleteSaleQuotation(data) {
    return async function () {
        const response = await axios.post('quotation/delete', data);
        return response;
    };
}
