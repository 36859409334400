// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    serviceItemList: [],
    totalRows: 0,
    rowsPerPage: 5,
    page: 0,
    selectedServiceItem: null,
    message: null,
    status: false,
    error: ''
};

const slice = createSlice({
    name: 'serviceItem',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setServiceItems(state, action) {
            state.serviceItemList = action.payload;
        },
        setServiceRowPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setServicePage(state, action) {
            state.page = action.payload;
        },
        setTotalRows(state, action) {
            state.totalRows = action.payload;
        },
        setSelectedServiceItem(state, action) {
            state.selectedServiceItem = action.payload;
        },
        setServiceItem(state, action) {
            const data = action.payload;
            const rowIndex = state.serviceItemList.findIndex((serviceItem) => serviceItem.id === data.id);
            if (rowIndex) {
                state.serviceItemList[rowIndex] = data;
            }
        }
    }
});

// Reducer
export default slice.reducer;
export const { setSelectedServiceItem, setServiceRowPerPage, setServicePage } = slice.actions;
// ----------------------------------------------------------------------

export function getServiceItems(data = {}) {
    return async () => {
        try {
            const response = await axios.post('/item/list', { ...data, stock_type: 'service' });
            if (response.data.status) {
                dispatch(slice.actions.setServiceItems(response.data.data));
                dispatch(slice.actions.setTotalRows(response.data.total_rows));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}
export function getServiceItem(id) {
    return async () => {
        try {
            const response = await axios.post('/item/details', { id });
            if (response.data.status) {
                dispatch(slice.actions.setItem(response.data.data));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function saveServiceItem(data) {
    return async function () {
        const response = await axios.post('item/save', data);
        return response;
    };
}

export function updateServiceItem(data) {
    return async function () {
        const response = await axios.post('item/update', data);
        return response;
    };
}

export function deleteServiceItem(data) {
    return async function () {
        const response = await axios.post('item/delete', data);
        return response;
    };
}
